import React from "react"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import icon1 from "../assets/icon/furniture.png"
import icon2 from "../assets/icon/home.png"
import icon3 from "../assets/icon/people.png"

import Layout from "../components/layout"
import Metadata from "../components/metadata"
import Hero from "../components/hero"

const IndexPage = ({ data }) => {
  const intl = useIntl()
  const { heroImage } = data

  return (
    <Layout>
      <Metadata title={intl.formatMessage({ id: "home.title" })} />
      <Hero image={heroImage} />
      <h1 className="d-flex m-2 p-3 justify-content-center align-items-center">
        {intl.formatMessage({ id: "home.subtitle" })}
      </h1>
      <hr />
      <div className="container">
        <div className="row py-3">
          <div className="col-sm-4 justify-content-center align-items-center">
            <img src={icon1} alt="furniture" />
            <p className="px-1 px-md-1 py-3">
              {intl.formatMessage({ id: "home.description.left" })}
            </p>
          </div>
          <div className="col-sm-4 justify-content-center align-items-center">
            <img src={icon2} alt="home" />
            <p className="px-1 px-md-1 py-3">
              {intl.formatMessage({ id: "home.description.center" })}
            </p>
          </div>
          <div className="col-sm-4 justify-content-center align-items-center">
            <img src={icon3} alt="people" />
            <p className="px-1 px-md-1 py-3">
              {intl.formatMessage({ id: "home.description.right" })}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query HomePageQuery {
    heroImage: file(relativePath: { eq: "home/hero.jpg" }) {
      childImageSharp {
        fluid {
          src
          srcSet
          aspectRatio
          sizes
        }
      }
    }
  }
`

export default IndexPage
